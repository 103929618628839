<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :max-height="600"
      :src="require('@/assets/light.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-8">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            class="hidden-sm-and-down"
            weight="regular"
            title="DATA SECURITY-AS-A-SERVICE SOLUTIONS"
          />
          <span class="hidden-md-and-up">
            DATA SECURITY-AS-A-SERVICE SOLUTIONS
          </span>

          <base-heading size="text-h2" title="FIRSTVAULT" weight="medium" />

          <base-body>
            Make your digital life hassle-free, safe & secure. Eliminate the
            need to remember and worry about passwords again. Protect your
            sensitive data against cyberattacks and ransomware. Securely store
            and access your sensitive data with one click from all your devices.
          </base-body>

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <base-btn to="/pricing"> Start Free </base-btn>
            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn to="/login"> User Login </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "70vh" : "30vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
